import React, { Component } from "react";
import { StarOutline } from "@material-ui/icons";
import "./MultipleFavEmailsWeb.css"
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

interface Props {
  isDark: any;
  length: any;
  MulptileFavAPIFunction : ()=>void;
  CancelMultipleFav :()=>void;
}
export default class MultipleFavEmailsWeb extends Component<Props> {
  global: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    //const arrowColor = this.props.selected ? "currentColor" : "#C4C7CC";
    const mode = this.context.theme;
    const isDark = this.props.isDark;
    const themeStyles = isDark ? darkStyles : lightStyles;
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
    });
    return (
      <ThemeProvider theme={theming}>
        <Grid
          className="grid-Part"
          style={{
            ...themeStyles.Grid,
            top: "19%",
            left: "39%",
            width: "61%",
            padding: "10px 10px 0px 0px",
            height: "81%",
            //  ...themeStyle.single_mail_cont,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position :"relative",
               flexDirection:"column"
            }}
          >
            <Box 
            // style={{
            //   position :"absolute"
            //   }}
              >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
               style={{ width: "80" , 
                paddingTop:"180"
              }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
            </Box>
            <Box><p style={{fontWeight : "bold"}}>{this.props.length} emails selected</p></Box>
            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Box style={{gap:"0.5em",display:"flex",alignItems:"center",justifyContent:"center",marginRight:"0.5em"}} className="hoverAdd" onClick={this.props.MulptileFavAPIFunction}>
              <Box style={{fontSize:"2em"}}><StarOutline /> </Box>
              <Box>Favorite</Box>
            </Box>
            <Box className="hoverCancel">
            <button style={{display:"flex",alignItems:"center",justifyContent:"center", gap:"0.5em", backgroundColor :  isDark ? "black" : "white",border:"none" , color:  isDark ? "white" : "black"}} onClick={this.props.CancelMultipleFav}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg> <span>Cancel</span>

            </button>
            </Box>
            

           
            </Box>
          </Box>
        </Grid>
      </ThemeProvider>
    );
  }
}

const lightStyles = {
  Grid: {
    backgroundColor: "#ffffff",
  },
  text: {
    color: "black",
  },
};
const darkStyles = {
  Grid: {
    backgroundColor: "#0a0a0a",
    border: "1px solid white",
  },
  text: {
    color: "white",
    fontWeight: "600",
  },
};
