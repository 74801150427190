import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Paper,
  Divider,

  // Customizable Area End
} from "@material-ui/core";
///////////////////////

//////////////////////
// Customizable Area Start
import "../../../web/src/App.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import {
  FilterList,
  MailOutline,
  CheckCircleOutline,
  Drafts,
  PresentToAll,
  Archive,
  Star,
  MoreHoriz,
  Reply,
  SubdirectoryArrowRight,
  ArrowDownward,
  StarOutline,
  NavigateNext,
  NavigateBefore,
} from "@material-ui/icons";
import AppHeader from "./AppHeader.web";
import moment from "moment";
import Cache2Controller from "./Cache2Controller.web";
import { Attachments } from "../../../components/src/Attachments.web";
import { darkLogo, lightLogo } from "./assets";
import Loader from "../../../components/src/Loader.web";
import Filteroptions from "../../filteritems/src/Filteroptions.web";
import SortingWeb from "../../../components/src/SortingWeb.web";
import FilterWeb from "../../../components/src/FilterWeb.web";
import "./Cache2Web.css";
import MultipleFavEmailsWeb from "../../../components/src/MultipleFavEmailsWeb.web";
import { Resizable } from 're-resizable';
// Customizable Area End

export default class Cache2 extends Cache2Controller {
  global: any;
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDataFromChild = (data: any) => {
    // Do something with the data received from the child
    this.handleAdvanceSearchFunction(data);
  };
  normalDetailArray = (data: any) => {
    this.setState({ normalSelectSearchMail: data, selectedMail: "" });
  };
  handleLogout = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_password");
  };

  // Customizable Area End

  render() {
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;
    const options = [
      { icon: <Drafts style={webStyle.icons} />, title: "Draft" },
      { icon: <PresentToAll style={webStyle.icons} />, title: "Sent Items" },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "16" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.6"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        ),
        title: "Downloaded",
      },
      { icon: <Star style={webStyle.icons} />, title: "Starred" },
    ];

    const theme = createTheme({
      palette: {
        primary: {
          main: "#fff",
          contrastText: "#fff",
        },
        secondary: {
          main: "#eaebff",
        },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
      typography: {
        subtitle1: {
          margin: "20px 0px",
        },
      },
    });

    const filteredEmail = this.state.filteredEmail;
    const emailList =  this.state.isFilter ? filteredEmail:  this.state.emailList;

    const emailsRender = emailList.map((item: any,index : any) => {
      return (
        <>
          <Box
            // key={item.attributes.save_set_id}
            style={{
              ...webStyle.mail_container,
              backgroundColor:
                this.state.selectedMail ===
                item.attributes.save_set_id
                  ? themeStyle.mail_container
                      .backgroundColor
                  : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                selectedMail: item.attributes.save_set_id,
                normalSelectSearchMail: null,
              });
            }}
            data-test-id="inboxEmailsTest3"
          >
            <Box style={webStyle.center_container}>
              <Box
                style={{
                  ...themeStyle.avatar_container,
                  ...webStyle.avatar_container,
                }}
              >
                <Typography
                  data-test-id="user_initials"
                  style={{
                    fontSize: "11px",
                    position: "relative",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleMultipleFavEmails(
                      item.attributes.save_set_id,
                      item.attributes.vault_id
                    );
                  }}
                >
                  {item.attributes.author[0].toUpperCase()}
                  {item.attributes.author[1].toUpperCase()}
                  {this.state.multipleFavArray.filter(
                    (e: any) =>
                      e.save_set_id ==
                      item.attributes.save_set_id
                  )?.length !== 0 && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "-12px",
                        right: "-10px",
                        borderRadius: "50%",
                        border: "none",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="white"
                        className="w-6 h-6"
                        style={{ width: "1.5em" }}
                      >
                        {" "}
                        <path
                          fill="#1204ba"
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box style={webStyle.info_container}>
              <Box style={webStyle.heading_container}>
                <Typography
                  data-test-id="from_email"
                  style={webStyle.title}
                >
                  {
                  item.attributes.author}
                </Typography>
                <Typography data-test-id="email_formDate">
                  {this.state.tabState == "Downloaded" ? this.formatDate(item.attributes.downloaded_date) : this.formatDate(item.attributes.sent_dt)}
                </Typography>
              </Box>
              <Typography style=
              {
                webStyle.title
              }
                data-test-id="email_subject"
              >
                {item.attributes.subject.charAt(0).toUpperCase() +
                  item.attributes.subject.slice(1)
                  }
              </Typography>
              <Typography 
              style={
                webStyle.sub_heading
                }>
                {
                item.attributes.subject}
              </Typography>
            </Box>
          </Box>
        </>
      );
    })

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Paper
          style={{
            height: "100vh",
            width: "100vw",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <Container maxWidth={"xl"} style={webStyle.container}>
            <Box sx={webStyle.container_child}>
              <Box
                sx={{
                  height: "100vh",
                  width: "13%",
                  ...themeStyle.bgColor,
                  ...themeStyle.borderRight,
                }}
              >
                <Box
                  sx={{
                    margin: "auto",
                    width: "fit-content",
                  }}
                >
                  <img
                    src={isDark ? darkLogo : lightLogo}
                    alt="company_logo"
                    width="65px"
                    height={"40px"}
                    style={webStyle.logo}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  <Button
                    style={{
                      textTransform: "none",
                      padding: "5px 8px",
                      fontWeight: 500,
                      width: "90%",
                      justifyContent: "left",
                      ...themeStyle.newBtnColor,
                    }}
                    data-test-id="composeEmailTest"
                    startIcon={
                      <MailOutline
                        style={{
                          marginLeft: "0.6em",
                          fontSize: "1.2em",
                          marginRight: "-0.1em",
                        }}
                      />
                    }
                    onClick={() => this.openOutlookCompose()}
                  >
                    New Email
                  </Button>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "90%",
                      marginTop: "20px",
                      fontWeight: 400,
                      marginLeft: "1.5em",
                      ...themeStyle.color,
                      ...themeStyle.options,
                    }}
                  >
                    <Box
                      style={{
                        width: "0em",
                        height: "-1em",
                        marginRight: "1.9em",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                        width="16"
                        height="16"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                        />
                      </svg>
                    </Box>
                    <Box
                      data-testId="carlysimonClick"
                      onClick={() => this.handleTabUpdate(this.state.username)}
                    >
                      {this.state.username}
                    </Box>
                  </Box>
                  {options.map((ele, key) => (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gridGap: "9px",
                        width: "90%",
                        margin: "auto",
                        marginTop: "20px",
                        fontWeight: 300,
                        color:
                          this.state.tabState === ele.title
                            ? themeStyle.color.color
                            : "",
                        ...themeStyle.options,
                      }}
                    >
                      <Box sx={{ paddingLeft: "13px", marginLeft: "-2px" }}>
                        <Typography>{ele.icon}</Typography>
                      </Box>
                      <Box
                        data-testId="eleTitle"
                        sx={{
                          padding: "10px 0px",
                          marginLeft: "-3px",
                        }}
                        onClick={() => this.handleTabUpdate(ele.title)}
                      >
                        <Typography style={{ fontSize: "13px" }}>
                          {ele.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box style={{ marginLeft: "1.8em", marginTop: "220px" }}>
                  {" "}
                  <Button onClick={this.handleLogout}>Logout</Button>
                </Box>
              </Box>
              <Box sx={{ width: "90%", padding: "0px" }}>
                <AppHeader
                  {...this.props}
                  open={false}
                  navigation={""}
                  id="1"
                  isDark={isDark}
                  searchData={{}}
                  handleSearchTerm={() => {}}
                  flag={this.state.flag}
                  onDataReceived={this.handleDataFromChild}
                  handleAdvanceSearchPop={this.handleAdvanceSearchPop}
                  clearFilterAdvanceSearch={this.clearFilterAdvanceSearch}
                  normalDetailArray={this.normalDetailArray}
                />
                <Box
                  sx={{
                    padding: "10px 0px",
                    ...themeStyle.bgColor,
                    ...themeStyle.borderBottom,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 10px",
                      gridGap: "20px",
                    }}
                  >
                    <Box sx={{ display: "flex", gridGap: "10px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gridGap: "5px",
                        }}
                      >
                        <FilterList style={webStyle.icons} />
                        <Box style={{ cursor: "pointer", fontSize: "14px" }}>
                          <Box
                            onClick={() => this.handlePopBtn("sort")}
                            data-test-id="sortPopOver"
                          >
                            Sort :{" "}
                            {this.state.sortTempOption ? (
                              this.state.sortTempOption == "sent_dt" ? (
                                "Date"
                              ) : (
                                this.state.sortTempOption
                              )
                            ) : (
                              <>
                                {this.state.selectedSortingOption === "" &&
                                  "Default"}
                                {this.state.selectedSortingOption === "sent_dt"
                                  ? "Date"
                                  : this.state.selectedSortingOption}
                              </>
                            )}
                          </Box>
                          {this.state.sortPopOpen && (
                            <>
                              <Backdrop
                                style={{
                                  background: "transparent",
                                  zIndex: 1300,
                                }}
                                open={this.state.sortPopOpen}
                                onClick={() =>
                                  this.setState({ sortPopOpen: false })
                                }
                                data-test-id="sortingwebBtn"
                              >
                                <SortingWeb
                                  handleClosePop={this.handleSortPopClose}
                                  selected={this.state.selectedSortingOption}
                                  handleSortingOptionChange={
                                    this.handleSortingOptionChange
                                  }
                                  sort_direction={this.state.sort_direction}
                                  isDark={isDark}
                                  handleSortingFunction={
                                    this.handleSortingFunction
                                  }
                                  toggleSortOrder={this.toggleSortOrder}
                                  defaultBtnSort={this.defaultBtnSort}
                                  selectedSortingOption={this.state.sort_column}
                                />
                              </Backdrop>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gridGap: "5px",
                        }}
                        data-test-id="filterPopOver"
                      >
                        <FilterList style={webStyle.icons} />
                        <Box
                          style={{ cursor: "pointer", fontSize: "14px" }}
                          onClick={() => this.handlePopBtn("filter")}
                          data-test-id="handlePopButton"
                        >
                          Filter
                        </Box>
                        {this.state.filterPopOpen && (
                          <>
                            <Backdrop
                              style={{
                                background: "transparent",
                                zIndex: 1300,
                              }}
                              open={this.state.filterPopOpen}
                              onClick={() =>
                                this.setState({ filterPopOpen: false })
                              }
                              data-test-id="settingModal"
                            >
                              <FilterWeb
                                handleClose={this.handleFilterPopClose}
                                handleClear={this.handleClearFilters}
                                isDark={isDark}
                                handleApplyFilterBtn={this.handleApplyFilterBtn}
                                data-test-id="filterPopClose"
                                selectedFilter={this.state.selectedFilter}
                                otherDays={this.state.otherDayValue}
                                updateFilter={this.handlerFilter}
                                checkDateBetween = {this.checkDateBetween}
                                tabState = {this.state.tabState}
                              />
                            </Backdrop>
                          </>
                        )} 
                        {
                        this.state.selectedFilter.size > 0 
                        && (
                          <Box
                            style={{
                              fontSize: "10px",
                              padding: "2px 3px",
                              borderRadius: "4px",
                              ...themeStyle.filter_count_color,
                            }}
                          >
                            {this.state.selectedFilter.size
                            }
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={webStyle.mail_det_wrapper}>
           
                  <Resizable
                    defaultSize={{
                      width: "30%",
                       height:"100%",
                    }}
                    maxWidth="50%"
                    minWidth="30%"
                    enable={{
                      top: false,
                      right: true,
                      bottom: false,
                      left: false,
                      topRight: false,
                      bottomRight: false,
                      bottomLeft: false,
                      topLeft: false,
                    }}
                  >
          
                    <Box
                      sx={{
                        ...webStyle.mails_container,
                        ...themeStyle.mails_container,
                      }}
                      className="scroll_bar_hide"
                    >
                     
                      <div data-test-id={"email_list"}>
                        {/* // populate the all emails  // */}

                          {emailsRender}

                        {/* //////////////////////////////// */}
                      </div>
                      <div style={{textAlign:"center"}} ref={this.emailPageEndRef}>
                  {(this.state.hasEmailResponseEmpty 
                  || this.state.tabState === "Draft"
                   ||  this.state.tabState ==="Sent Items" ||  this.state.isFilter ) 
                    ? "No Data found!..": "Loading..."}
                  </div>
                    
                    </Box>
                   </Resizable>
                  {/* populate the single email */}
                  {this.state.multipleFavArray.length == 0 &&
                    this.state.normalSelectSearchMail && (
                      <div
                        style={{
                          width: "68%",
                          padding: "10px 10px 0px 0px",
                          height: "fit-content",
                          ...themeStyle.single_mail_cont,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          <Typography style={{ fontWeight: 600 }}>
                            {this.state.normalSelectSearchMail.attributes.subject
                              .charAt(0)
                              .toUpperCase() +
                              this.state.normalSelectSearchMail.attributes.subject.slice(
                                1
                              )}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "10%",
                              ...themeStyle.icon_color,
                            }}
                          >
                            <Archive />
                            <Box
                              onClick={() =>
                                this.handleStarClick(
                                  this.state.normalSelectSearchMail
                                )
                              }
                              style={{ cursor: "pointer" }}
                              data-test-id="startClick"
                            >
                              {this.state.starredEmails.find(
                                (i: any) =>
                                  i.id === this.state.normalSelectSearchMail.id
                              ) ? (
                                <Star />
                              ) : (
                                <StarOutline />
                              )}
                            </Box>
                            <MoreHoriz />
                          </Box>
                        </Box>
                        <Divider style={{ width: "100%" }} />
                        <Box
                          sx={{
                            display: "flex",
                            gridGap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          {/* profile pic */}
                          <Box
                            sx={{
                              ...webStyle.avatar_container,
                              ...themeStyle.avatar_container,
                            }}
                          >
                            <Typography style={{ fontSize: "11px" }}>
                              {this.state.normalSelectSearchMail.attributes.author[0].toUpperCase()}
                              {this.state.normalSelectSearchMail.attributes.author[1].toUpperCase()}
                            </Typography>
                          </Box>
                          {/* userName */}
                          <Box>
                            <Typography>
                              {
                                this.state.normalSelectSearchMail.attributes
                                  .author
                              }
                            </Typography>
                            <Box style={{ fontSize: "0.7em" }}>
                              {moment(
                                this.state.normalSelectSearchMail.attributes
                                  .sent_dt
                              ).format("DD-MMM HH:MM a")}
                            </Box>
                          </Box>
                        </Box>
                        {/* message content */}
                        <Typography
                          style={{ marginTop: "10px", fontSize: "15px" }}
                        >
                          {this.state.normalSelectSearchMail.attributes.subject}
                        </Typography>
                        <Divider style={{ marginTop: "10px" }} />
                        {/* attachments */}
                        {/* {ele.attributes.has_attachment &&  */}
                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "10px",
                            gridGap: "15px",
                            marginBottom: "5px",
                          }}
                        >
                          {/* <AttachFile /> */}
                          {/* <Typography>{ele.attributes[8]}</Typography> */}
                          <Attachments isDark={isDark} />
                          <Attachments isDark={isDark} />
                        </Box>
                        {/* } */}

                        <Box
                          sx={{
                            display: "flex",
                            marginTop: "20px",
                            gridGap: "10px",
                          }}
                        >
                          <Box
                            sx={webStyle.reply_forword_btns}
                            onClick={() =>
                              this.openOutLookReply(
                                this.state.normalSelectSearchMail.attributes
                                  .sender_email
                              )
                            }
                            data-test-id="openOutLookReplyNormal"
                          >
                            <NavigateBefore /> Reply
                          </Box>
                          <Box
                            sx={webStyle.reply_forword_btns}
                            onClick={() =>
                              this.openOutLookForward(
                                this.state.normalSelectSearchMail.attributes
                                  .sender_email,
                                this.state.normalSelectSearchMail.attributes
                                  .subject,
                                this.state.normalSelectSearchMail.attributes
                                  .body
                              )
                            }
                            data-test-id="openOutLookForwardNormal"
                          >
                            {" "}
                            <NavigateNext />
                            Forward
                          </Box>
                        </Box>
                      </div>
                    )}
                  {this.state.multipleFavArray.length !== 0 && (
                    <MultipleFavEmailsWeb
                      isDark={isDark}
                      length={this.state.multipleFavArray.length}
                      MulptileFavAPIFunction={this.MulptileFavAPIFunction}
                      CancelMultipleFav={this.CancelMultipleFav}
                    />
                  )}
                  {this.state.multipleFavArray.length == 0 &&
                    this.state.emailList
                      ?.filter((ele: any) =>ele.attributes.save_set_id === this.state.selectedMail
                      )
                      .map(
                        (ele: any) => (
                        <div
                          style={
                            {
                            width: "68%",padding: "10px 10px 0px 0px",
                            height: "fit-content",
                            ...themeStyle.single_mail_cont,
                          }
                        }
                        >
                          <Box
                            sx=
                            {
                              {
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingBottom: "10px",
                            }
                          }
                          >
                            <Typography 
                            style=
                            {
                              { fontWeight: 600 }
                              }
                              >
                              {
                              ele.attributes.subject.charAt(0).toUpperCase() +
                                ele.attributes.subject.slice(1)
                                }
                            </Typography>
                            <Box
                              sx=
                              {
                                {
                                display: "flex",
                                justifyContent: "space-between",
                                width: "10%",
                                ...themeStyle.icon_color,
                              }
                            }
                            >
                              <Box
                                onClick=
                                {() =>this.handleIndividualDownload(ele.attributes.vault_id,
                                    ele.attributes.save_set_id)
                                }
                                style={{ cursor: "pointer" }}
                                data-test-id="IndividualDownloadFunction"
                              >
                                <Archive />
                              </Box>
                              <Box
                                onClick={() => this.handleStarClick(ele)}
                                style={{ cursor: "pointer" }}
                                data-test-id="startClick"
                              >
                                {ele.attributes.is_favourite ? (
                                  <Star />
                                ) : (
                                  <StarOutline />
                                )}
                              </Box>
                              <MoreHoriz />
                            </Box>
                          </Box>
                          <Divider style={{ width: "100%" }} />
                          <Box
                            sx={{
                              display: "flex",
                              gridGap: "10px",
                              marginTop: "20px",
                            }}
                          >
                            {/* profile pic */}
                            <Box
                              sx={{
                                ...webStyle.avatar_container,
                                ...themeStyle.avatar_container,
                              }}
                            >
                              <Typography style={{ fontSize: "11px" }}>
                                {ele.attributes.author[0].toUpperCase()}
                                {ele.attributes.author[1].toUpperCase()}
                              </Typography>
                            </Box>
                            {/* userName */}
                            <Box>
                              <Typography>{ele.attributes.author}</Typography>
                              <Box style={{ fontSize: "0.7em" }}>
                                {moment(ele.attributes.sent_dt).format(
                                  "DD-MMM HH:MM a"
                                )}
                              </Box>
                            </Box>
                          </Box>
                          {/* message content */}
                          <Typography
                            style={{ marginTop: "10px", fontSize: "15px" }}
                          >
                            {ele.attributes.subject}
                          </Typography>
                          <Divider style={{ marginTop: "10px" }} />
                          {/* attachments */}
                          {/* {ele.attributes.has_attachment &&  */}
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "10px",
                              marginBottom: "5px",
                              gridGap: "15px",
                            }}
                          >
                            {/* <AttachFile /> */}
                            {/* <Typography>{ele.attributes[8]}</Typography> */}
                            <Attachments isDark={isDark} />
                            <Attachments isDark={isDark} />
                          </Box>
                          <Box sx={
                            {
                              display: "flex",gridGap: "10px",
                              marginTop: "20px",
                            }
                          }>
                            <Box
                              sx=
                              {
                                webStyle.reply_forword_btns
                              }
                              onClick={() =>this.openOutLookReply(ele.attributes.sender_email)
                              }
                              data-test-id=
                              "openOutLookReply"><NavigateBefore />
                              Reply
                            </Box>
                            <Box sx={
                                webStyle.reply_forword_btns}
                              onClick={() => this.openOutLookForward(ele.attributes.sender_email,ele.attributes.subject,ele.attributes.body)}
                              data-test-id="openOutLookForward">
                              {" "}
                              <NavigateNext />
                              Forward
                            </Box>
                          </Box>
                        </div>
                      ))}

                  {/* //////////////////////// */}
                
                </Box>
              </Box>
            </Box>
          </Container>
        </Paper>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
///////////////////////////////

/////////////////////////////////
const lightStyles = {
  bgColor: {
    backgroundColor: "#f6f7f9",
  },
  borderRight: {
    borderRight: "1px solid #efefef",
  },
  borderBottom: {
    borderBottom: "1px solid #efefef",
  },
  color: {
    color: "#0a2cf0", //deep blue
  },
  newBtnColor: {
    color: "#0d1384", //dark blue
    backgroundColor: "#eaebff",
  },
  options: {
    cursor: "pointer",
    fontSize: "13px",
  },
  filter_count_color: {
    backgroundColor: "#434343",
    color: "white",
    border: "2px solid #F5F202",
  },
  icon_color: {
    color: "#0a2cf0", //blue
  },
  mails_container: {
    borderRight: "0.5px solid #f3f3fd",
    backgroundColor: "white",
  },
  mail_container: {
    backgroundColor: "#cecfd5", //grey
  },
  single_mail_cont: {
    backgrounColor: "white",
  },
  avatar_container: {
    backgroundColor: "#7E8CCA",
  },
};
const darkStyles = {
  bgColor: {
    backgrounColor: "#202020",
    borderRight: "1px solid #2c2c2c",
  },
  borderBottom: {
    borderBottom: "1px solid #2c2c2c",
  },
  borderRight: {
    borderRight: "1px solid #2c2c2c",
  },
  color: {
    // color: "#fbf502",
    color: "yellow",
  },
  mails_container: {
    borderRight: "0.5px solid #242424",
  },
  mail_container: {
    backgroundColor: "#242424",
  },
  newBtnColor: {
    color: "#ffffff",
    backgroundColor: "#202020",
  },
  options: {
    cursor: "pointer",
    fontSize: "13px",
  },
  filter_count_color: {
    color: "#F5F202",
    border: "2px solid #F5F202",
  },
  icon_color: {
    // color: "#eee403", //yellow
    // color:"#8e9091"
    color: "yellow",
  },
  single_mail_cont: {
    // height : "min -content",
    // boxSizing : "border-box",
    backgrounColor: "#262626", //grey
    // paddingBottom :"8px",
    // paddingLeft: "10px",
  },
  avatar_container: {
    backgroundColor: "#2c2c2c",
  },
};
const webStyle = {
  container: {
    padding: "0px",
    width: "100%",
    height: "100%",
  },
  container_child: {
    display: "flex",
  },
  logo: {
    paddingTop: "10px",
  },
  icons: {
    fontSize: "15px",
    textAligh: "left",
    // color:"#8e9091"  //grey
  },
  checkCircle: {
    fontSize: "20px",
    marginRight: "5px",
  },
  options: {
    cursor: "pointer",
  },
  mail_det_wrapper: {
    display: "flex",
    gap: "10px",
  },
  center_container: {
    display: "flex",
  },
  mails_container: {
    // width: "30%",
    height: "86vh",
    overflow: "scroll",
    borderRight: "1px solid #2c2c2c",
  },
  mail_container: {
    display: "flex",
    margin: "8px 0px",
    padding: "8px",
    borderLeft: "none",
    cursor: "pointer",
    borderColor: "#2c2c2c",
    // backgroundColor: "#2c2c2c",
  },
  heading_container: {
    width: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // background: "green",
  },

  info_container: {
    marginLeft: "8px",
    width: "100%",
  },
  title: {
    fontSize: "16px",
  },
  avatar_container: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#7e8cca",
    color: "white",
  },
  sub_heading: {
    fontSize: "14px",
    color: "#a6a6a6",
    fontStyle: "italic",
  },
  attachments_container: {
    borderRadius: "10px",
    backgroundColor: "#a9a9a9",
    padding: "5px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    border: "1px solid green",
  },
  reply_forword_btns: {
    display: "flex",
    textAlign: "center",
    padding: "5px 10px",
    border: "1px solid grey",
    borderRadius: "20px",
    gridGap: "5px",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
  },
  sort_opt_box: {
    border: "1px solid red",
    position: "absolute",
    top: "14%",
    left: "23.5%",
    width: "21%",
    backgroundColor: "#f6f7f9",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  sort_opt: {
    display: "flex",
    gridGap: "5px",
    alignItems: "center",
    padding: "5px 10px",
    cursor: "pointer",
  },
  cancel_btn: {
    backgroundColor: "white",
    color: "#656565",
    borderRadius: "5px",
  },
  attachments: {
    border: "1px solid grey",
    padding: "5px",
  },
};

// Customizable Area End
