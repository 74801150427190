import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import NetInfo from "@react-native-community/netinfo";
import secureLocalStorage from "react-secure-storage";
import { Response } from "./Cache2Controller";
import { ThemeContext } from "../../../framework/src/ThemeContext/ThemeProvider";
export const configJSON = require("./config");
let baseURL = require("../../../framework/src/config");
import React from "react";
import moment from "moment";

export interface Props {
  navigation?: any;
  id?: string;
  isDark?: boolean;
  searchData?: Object;
  handleSearchTerm?: any;
  open?: boolean;
  advancedsearchList?: [];
  dataEmail?: any;
  handleAdvanceSearchPop?: any;
  onDataReceived?: any;
  timestamp?: any;
  clearFilterAdvanceSearch?: any;
  normalDetailArray?: any;
  flag?: any;
  dataSyncState?: any;
  dataSyncPostAPI?: any;
}

export interface EmailRoot {
  data: EmailData[];
}
interface IApiInterceptor {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: string;
}

export interface EmailData {
  id: string;
  type: string;
  attributes: EmailDataAttributes;
}

export interface EmailDataAttributes {
  uid: string;
  save_set_id: string;
  vault_id: string;
  author: string;
  sent_dt: string;
  recipient_string: string;
  subject: string;
  importance: number;
  has_attatchments: boolean;
  conversation_topic: string;
  body: string;
  sender_email: string;
  receiver_email: string[];
  email_status: string;
  is_favourite: boolean;
  size: number;
}

export type TFilter = {
  title: string;
  type: "number" | "boolean" | "string" | "onchange";
  value: any;
  key: string;
  callback: (value: any) => boolean;
};
interface S {
  emailList: Array<any>;
  hasSorted: "default" | "sorted";
  selectedFilter: Map<string, TFilter>;
  otherDayValue: string;
  disabledFilterKey: Array<string>;
  isFilter: boolean;
  filteredEmail: Array<any>;


  selectedMail: any;
  searchTerm: any;
  sortPopOpen: any;
  filterPopOpen: any;
  tabState: any;
  settingsPopOpen: boolean;
  advanceSearchPop: boolean;
  username: any;
  password: any;
  startIndex: number;
  maxResults: any;
  starredEmails: any;
  selectedEmail: boolean;
  toggleFavoriteArray: boolean;
  advanceList: any;
  advanceListState: any;
  datePickerOpen: boolean;
  from: any;
  to: any;
  subject: string;
  has_word: string;
  size: string;
  has_attachment: boolean;
  isOpen: boolean;
  date: any;
  hasEmailResponseEmpty: boolean;
  advancedsearchList: any;
  normalSearchArray: any;
  onClickAdvanceSearch: boolean;
  demoAdvanceState: boolean;
  selectedSortingOption: any;
  sortByStateOption: boolean;
  optionsSortingState: any;
  normalSelectSearchMail: any;
  sort_column: any;
  sort_direction: any;
  wordtypedFrom: boolean;
  wordtypedTo: boolean;
  size_unit: string;
  size_condition: any;
  days: any;
  vault_id: any;
  save_set_id: any;
  isClickedMultipleFav: boolean;
  multipleFavArray: any;
  clickedSaveSetId: any;
  clickedVaultId: any;
  responseMultipleFavEmails: any;
  flag: boolean;
  dataSyncState: any;
  responseGetDataSync: any;
  responseSETDataSync: any;
  sortTempOption: any;
  sortTempDirection: any;
  sortTempColumn: any;
  filterTempCheckBox: any;
  width: any;
  height: any;
}

interface SS {
  id: any;
}

export default class Cache2Controller extends BlockComponent<Props, S, SS> {

  emailAPIRequestId: string = "";
  downloadEmailAPIRequestId: string = "";
  starredEmailAPIRequestId: string = "";


  scroller: any = React.createRef();
  emailPageEndRef = React.createRef<HTMLDivElement>();
  debounceTimer: any = "";
  postDataSyncKey: any = "";
  dataSyncGetAPIKey: any = "";
  MultipleFavEmailsKey: any = "";
  normalSearchKey: string = "";
  advancedsearchApiCallId: any;
  ToggleAPIKey: string = "";
  static contextType = ThemeContext;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {

      emailList: [],
      hasSorted: "default",
      selectedFilter: new Map(),
      otherDayValue: "",
      disabledFilterKey: [],
      isFilter: false,
      filteredEmail: [],
      selectedMail: "",
      searchTerm: "",
      sortPopOpen: false,
      filterPopOpen: false,
      settingsPopOpen: false,
      advanceSearchPop: false,
      username: localStorage.getItem("user_name"),
      password: localStorage.getItem("user_password"),
      tabState: localStorage.getItem("user_name"),
      startIndex: 0,
      maxResults: 10,
      starredEmails: [],
      selectedEmail: false,
      toggleFavoriteArray: false,
      advanceList: [],
      advanceListState: false,
      datePickerOpen: false,
      from: "",
      to: "",
      subject: "",
      has_word: "",
      size: "",
      has_attachment: false,
      isOpen: false,
      date: "",
      advancedsearchList: [],
      normalSearchArray: [],
      onClickAdvanceSearch: false,
      demoAdvanceState: false,
      selectedSortingOption: "",
      sortByStateOption: false,
      optionsSortingState: "",
      normalSelectSearchMail: "",
      sort_column: "",
      sort_direction: "",
      wordtypedFrom: false,
      wordtypedTo: false,
      size_unit: "",
      size_condition: "",
      days: "",
      vault_id: "",
      save_set_id: "",
      hasEmailResponseEmpty: false,
      multipleFavArray: [],
      isClickedMultipleFav: false,
      clickedSaveSetId: false,
      clickedVaultId: false,
      responseMultipleFavEmails: [],
      flag: false,
      dataSyncState: "",
      responseGetDataSync: {},
      responseSETDataSync: {},
      sortTempOption: "",
      sortTempDirection: "",
      sortTempColumn: "",
      filterTempCheckBox: [],
      width: "",
      height: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    
      if (requestCallId === this.emailAPIRequestId) {
        this.handlerInboxEmail(response.data);
      }
      if (requestCallId === this.downloadEmailAPIRequestId) {
        this.handlerDownloadedEmail(response.data);
      }
      if (requestCallId === this.starredEmailAPIRequestId) {
        this.handlerStarredEmail(response.data);
      }

      if (requestCallId === this.advancedsearchApiCallId) {
        this.setState({
          emailList: response.data,
        });
        this.setAdvanceSearch(response.data);
      }
      if (this.ToggleAPIKey === requestCallId) {
        this.setState({
          toggleFavoriteArray: response.data,
        });
      }
      if (requestCallId === this.normalSearchKey) {
        if (this.state.searchTerm) {
          this.setState({
            normalSearchArray: response.data,
          });
        }
      }

      if (this.MultipleFavEmailsKey === requestCallId) {
        this.setState({
          responseMultipleFavEmails: response.data,
          multipleFavArray: [],
          selectedMail: [],
        });
      }
      if (this.dataSyncGetAPIKey === requestCallId) {
        this.setState({ responseGetDataSync: this.state.dataSyncState });
      }
      if (this.postDataSyncKey === requestCallId) {
        this.setState({ responseSETDataSync: this.state.dataSyncState });
      }
    }
  }

  componentDidMount = async () => {
    const emailObs = this.observerListener((entries) => {
      if (entries[0].isIntersecting) {
        //  no api, if empty response
        if (
          this.state.hasEmailResponseEmpty ||
          this.state.tabState === "Draft" ||
          this.state.tabState === "Sent Items" ||
          this.state.isFilter
        ) {
          return;
        }
        const usernameKey = this.state.username;
        const starredKey = "Starred";
        const downloadedKey = "Downloaded";

        const emailVariant = new Map([
          [usernameKey, () => this.fetchInboxEmails()],
          [starredKey, () => this.fetchStarredEmail()],
          [downloadedKey, () => this.fetchDownloadedEmail()],
        ]);

        if (this.state.emailList.length === 0) {
          const emailFn = emailVariant.get(this.state.tabState);
          if (emailFn) {
            emailFn();
          }
        } else {
          // scroll
          this.setState(
            (prev) => ({
              startIndex: prev.startIndex + prev.maxResults,
            }),
            () => {
              const emailFn = emailVariant.get(this.state.tabState);
              if (emailFn) {
                emailFn();
              }
            }
          );
        }
      }
    });
    if (this.emailPageEndRef.current) {
      emailObs.observe(this.emailPageEndRef.current);
    }
  };
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (prevProps.flag != this.props.flag) {
      this.setState({
        searchTerm: "",
        from: "",
        to: "",
        subject: "",
        has_word: "",
        size: "",
        date: "",
        has_attachment: false,
        days: "",
        size_condition: "",
        normalSelectSearchMail: [],
        normalSearchArray: [],
        isOpen: false,
        wordtypedFrom :  false,
        wordtypedTo : false,
      });
    }
    if (
      prevState.selectedSortingOption != this.state.selectedSortingOption &&
      this.state.selectedSortingOption != ""
    ) {
      this.setState({ sort_direction: "asc" });
    }
  }

  observerListener = (
    callback: IntersectionObserverCallback
  ): IntersectionObserver => {
    return new IntersectionObserver(callback, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });
  };


  handleReset = (value: string, callback: () => void) => {
    this.setState(
      {
        tabState: value,
        selectedSortingOption: "",
        starredEmails: [],
        selectedMail: null,
        multipleFavArray: [],

        startIndex: 0,

        // new
        emailList: [],
        hasEmailResponseEmpty: false,
        sort_direction: "",
        sort_column: "",
        hasSorted: "default",
        filteredEmail: [],
        selectedFilter: new Map(),
        isFilter: false,
        wordtypedFrom : false,
        wordtypedTo : false,
      },
      callback
    );
  };

  fetchInboxEmails = async () => {
    const body = {
      user_name: this.state.username,
      user_password: this.state.password,
      startIndex: this.state.startIndex,
      maxResults: this.state.maxResults,
      search: 0,
      ...(this.state.hasSorted === "sorted" && {
        sort_column: this.state.sort_column,
        sort_direction: this.state.sort_direction,
      }),
    };

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: configJSON.apiEndPointURL,
      method: "POST",
      body: JSON.stringify(body),
    };
    this.emailAPIRequestId = await this.apiInterceptor(apiInterceptorArgs);
  };

  fetchDownloadedEmail = async () => {
    let endPoint = `bx_block_api_search/api_searches/get_all_downloaded_emails?user_name=${this.state.username}&is_downloaded=true&startIndex=${this.state.startIndex}&maxResults=${this.state.maxResults}&sort_column=downloaded_date`;

    if (this.state.hasSorted === "sorted") {
      endPoint = `${endPoint}&sort_direction=${this.state.sort_direction}&sort_column=${this.state.sort_column}`;
    }

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint: endPoint,
      method: "GET",
    };
    this.downloadEmailAPIRequestId = await this.apiInterceptor(
      apiInterceptorArgs
    );
  };

  fetchStarredEmail = async () => {
    let endPoint = `bx_block_api_search/api_searches/get_all_favourite_emails?user_name=${this.state.username}&is_favourite=true&startIndex=${this.state.startIndex}&maxResults=${this.state.maxResults}`;

    if (this.state.hasSorted === "sorted") {
      endPoint = `${endPoint}&sort_direction=${this.state.sort_direction}&sort_column=${this.state.sort_column}`;
    }

    const apiInterceptorArgs: IApiInterceptor = {
      endPoint,
      method: "GET",
    };
    this.starredEmailAPIRequestId = await this.apiInterceptor(
      apiInterceptorArgs
    );
  };

  handlerInboxEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: [...prevState.emailList, ...responseData],
      hasEmailResponseEmpty: isEmpty,
    }));
  };

  handlerDownloadedEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: [...prevState.emailList, ...responseData],
      hasEmailResponseEmpty: isEmpty,
    }));
  };

  handlerStarredEmail = (responseData: any) => {
    const isEmpty = responseData.length > 0 ? false : true;
    this.setState((prevState) => ({
      emailList: [...prevState.emailList, ...responseData],
      hasEmailResponseEmpty: isEmpty,
    }));
  };

  handlerFilter = (data: TFilter) => {
    const selectedFilter = new Map(this.state.selectedFilter);
    const timeFilters = [
      "Last 7 days",
      "Last 30 days",
      "Last 60 days",
      "otherDays",
    ];
    const attachmentFilter = "Has attachment";

    // If the selected filter is one of the time-based options, disable all time-based options
    if (timeFilters.includes(data.title)) {
      selectedFilter.delete("otherDays");
      this.setState({ otherDayValue: "" });
      timeFilters.forEach((filter) => {
        if (filter === data.title) {
          selectedFilter.set(filter, data);
        } else {
          selectedFilter.delete(filter);
        }
      });
    }

    // If the selected filter is "Has Attachment," toggle its state
    if (data.title === attachmentFilter) {
      if (selectedFilter.has(attachmentFilter)) {
        selectedFilter.delete(attachmentFilter);
      } else {
        selectedFilter.set(attachmentFilter, {
          type: "boolean",
          title: "Has attachment",
          value: true, // Set this to true if you want to enable it by default
          key: "has_attatchments",
          callback: (item: any) => item === true,
        });
      }
    }
    if (data.title === "otherDays") {
      this.setState({
        otherDayValue: data.value, // Assuming data.value contains the value for "otherDays"
      });
    }
    this.setState({
      selectedFilter: selectedFilter,
    });
  };
  checkDateBetween(date: string, days: number) {
    const currentDate = moment().utc();
    const dateToCheck = moment(date).utc();
    const daysDifference = currentDate.diff(dateToCheck, "days");

    if (daysDifference <= days) {
      return true;
    }

    return false;
  }

  filterEmails = () => {
    const emailList = this.state.emailList;

    const filterData = 
    [...this.state.selectedFilter].map(
      ([, value]) => {
      const newKey = value.key;const newValue = value.value;const callback = value.callback;
      return [newKey, newValue, callback];
    }
    );

    const filterEmail = 
    emailList
    .filter(
      (item: any) => 
      {
      return filterData.every(([key, value, callback]) => 
      {
        return callback(item.attributes[key]);
      }
      );
    }
    );

    if (this.state.isFilter) {
      return filterEmail;
    }

    return emailList;
  };

  handleApplyFilterBtn = () => {
    const emailList = this.state.emailList;

    const filterData = [...this.state.selectedFilter]
    .map(([, value]) => 
    {
      const newKey = value.key;
      const newValue = value.value;
      const callback = value.callback;
      return [newKey, newValue, callback];
    });

    const filterEmail = emailList
    .filter((item: any) => 
    {
      return filterData.every(([key, value, callback]) => 
      {
        return callback(item.attributes[key]);
      });
    });

    this.setState(
      {
      filteredEmail: filterEmail,filterPopOpen: false,
      isFilter: filterData.length > 0 ? true : false,
      selectedMail : null,
    });
  };

  apiInterceptor = async (apiInterceptorArgs: IApiInterceptor) => {
    const header = {
      "Content-Type": apiInterceptorArgs.contentType || "application/json",
    };

    const endPoint = apiInterceptorArgs.endPoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiInterceptorArgs.method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      apiInterceptorArgs.body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };


  handleToggleAPI = (
    save_set_id: any,
    vault_id: any,
    is_favourite: boolean
  ) => {
    const header = {
      "Content-Type": "application/json",
    };
    const payload = {
      // vault_id: "1783C4E77F4E533499DF9C6AFB64D41B81110000evserv1",
      // vault_id: `${vault_id}`,
      // save_set_id: `${save_set_id}`,
      // favourite_checked: is_favourite,
      record_ids: [{ vault_id: `${vault_id}`, save_set_id: `${save_set_id}` }],
      favourite_checked: is_favourite,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ToggleAPIKey = requestMessage.messageId; //reference

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/toggle_favourites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleStarClick = (emailData: any) => {
    const { starredEmails } = this.state;
    const findStarreredEmail = starredEmails.find(
      (i: any) => i.id === emailData.id
    );
    if (findStarreredEmail) {
      const filterStarredEmails = starredEmails.filter(
        (i: any) => i.id !== emailData.id
      );
      this.setState({ starredEmails: filterStarredEmails });
    } else {
      const newStarredEmail = [...starredEmails, emailData];
      this.setState({ starredEmails: newStarredEmail });
    }
    let { tabState } = this.state;
    this.handleToggleAPI(
      emailData.attributes.save_set_id,
      emailData.attributes.vault_id,
      !emailData.attributes.is_favourite
    );
    if (tabState == "Starred") {
      this.setState({
      
        emailList: [],
        selectedMail: null,
        startIndex: 0,
      });
    
      this.fetchStarredEmail();
      this.setState({ selectedMail: null });
    } else if (tabState == "Downloaded") {
      this.setState({
        startIndex: 0,
        emailList: [],
      },()=>{this.fetchDownloadedEmail();});

    } else {
      this.setState({
        startIndex: 0,
        emailList: [],
      });
    
      this.fetchInboxEmails();
    }

    this.setState({
      tabState: tabState,
    });
  };

  handleTabUpdate = (value: string) => {
    this.setState({ flag: !this.state.flag });
    this.setState({
      advanceListState: !this.state.advanceListState,
      isOpen: false,
      filterPopOpen: false,
      normalSelectSearchMail: null,
      startIndex: 0,
    });

    if (value === "Starred") {
      this.handleReset(value, () => this.fetchStarredEmail());
    } else if (value === "Downloaded") {
      this.handleReset(value, () => this.fetchDownloadedEmail());
    } else if (value === "Sent Items") {
      this.setState({
        tabState: value,

        selectedMail: null,

        starredEmails: [],

        multipleFavArray: [],

        selectedSortingOption: "",

        emailList: [],
        filteredEmail: [],
        selectedFilter: new Map(),
        isFilter: false,
      });
    } else if (value === "Draft") {
      this.setState({
        tabState: value,

        selectedMail: null,

        starredEmails: [],

        multipleFavArray: [],

        selectedSortingOption: "",

        emailList: [],
        filteredEmail: [],
        selectedFilter: new Map(),
        isFilter: false,
        otherDayValue: "",
      });
    } else if (value === this.state.username) {
      this.handleReset(value, () => this.fetchInboxEmails());
    }
    this.setState({
      sortTempColumn: "",
      sortTempDirection: "",
      sortTempOption: "",
    });
  };

  handlePopBtn = (value: string) => {
    switch (value) {
      case "sort":
        this.setState({ filterPopOpen: false });
        this.setState({ sortPopOpen: !this.state.sortPopOpen });
        if (this.state.selectedSortingOption) {
          this.setState({
            sortTempOption: this.state.selectedSortingOption,
            sortTempDirection: this.state.sort_direction,
            sortTempColumn: this.state.sort_column,
          });
        }
        break;
      case "filter":
        this.setState({ sortPopOpen: false });
        this.setState({ filterPopOpen: !this.state.filterPopOpen });
        break;
      default:
        return;
    }
  };

  handleSortPopClose = () => {
    this.setState({
      sortPopOpen: false,
      //  selectedSortingOption : "",
      selectedSortingOption: this.state.sortTempOption,
      sort_direction: this.state.sortTempDirection,
      sortTempColumn: this.state.sort_column,
      sortTempOption: "",
    });
  };
  handleSortingOptionChange = (sortingOption: string) => {
    if (sortingOption == "Date") {
      sortingOption = "sent_dt";
      this.setState({
        selectedSortingOption: sortingOption,
        sort_column: "sent_dt",
      });
      this.toggleSortOrder();
      return;
    }

    this.setState({
      selectedSortingOption: sortingOption,
      sort_column: sortingOption.toLowerCase(),
    });
    this.toggleSortOrder();
  };
  handleSortingFunction = () => {
    if (this.state.tabState === "Starred") {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchStarredEmail();
        }
      );
    } else if (this.state.tabState === "Downloaded") {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchDownloadedEmail();
        }
      );
      // Call the appropriate sorting function
    } else {
      this.setState(
        {
          sortByStateOption: true,
          sortPopOpen: false,

          selectedMail: null,

          emailList: [],
          hasSorted: "sorted",
          startIndex: 0,
        },
        () => {
          this.fetchInboxEmails();
        }
      );
    }
    this.setState({
      sortTempColumn: "",
      sortTempDirection: "",
      sortTempOption: "",
    });
  };
  defaultBtnSort = () => {
    this.setState({
      selectedSortingOption: "",
      sort_direction: "",
      sort_column: "",
    });
  };

  toggleSortOrder = () => {
    this.setState((prevState) => ({
      sort_direction: prevState.sort_direction == "desc" ? "asc" : "desc",
    }));
  };

  handleFilterPopClose = () => {
    this.setState({ filterPopOpen: false });
  };

  handleClearFilters: Function = () => {
    let selectedFilter = this.state.selectedFilter;
    selectedFilter.clear();
    this.setState({ selectedFilter: selectedFilter, otherDayValue: "" });
  };
  // //////////////////////////////////
  handleDateChange = (dateEvent: any) => {
    const extractedDate = moment(dateEvent).format("YYYY-MM-DD");
    this.setState({ date: extractedDate });
    this.setState({ datePickerOpen: false });
  };
  handleInput = (e: any) => {
    const { name, value } = e.target;
    if (name === "days") {
      this.setState({
        days: e.target.value,
      });
    }
    if (name === "size_condition") {
      this.setState({
        size_condition: e.target.value,
      });
    }
    if (name === "from") {
      this.setState({
        from: value,
        wordtypedFrom: e.target.value !== "",
      });
    }
    if (name === "to") {
      this.setState({
        to: e.target.value,
        wordtypedTo: e.target.value !== "",
      });
    }
    if (name === "subject") {
      this.setState({
        subject: e.target.value,
      });
    }
    if (name === "has_word") {
      this.setState({
        has_word: e.target.value,
      });
    }
    if (name === "size") {
      this.setState({
        size: e.target.value,
      });
    }
    if (name === "date") {
      this.setState({
        date: this.state.date,
        // date : this.state.selectedDate
      });
    }
    if (name === "has_attachment") {
      this.setState((prevState) => ({
        has_attachment: !prevState.has_attachment,
      }));
    }
    if (name === "size_unit") {
      this.setState({
        size_unit: e.target.value,
      });
    }
  };
  handleDatePicker = () => {
    this.setState({ datePickerOpen: !this.state.datePickerOpen });
  };
  handleAdvanceSearchFunction = async (data: any) => {
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody = {
      // "user_name": "carly.simon",
      // "user_password": "P@55word",
      // "startIndex": 0,
      // "maxResults": 3,
      // "from": this.state.from,
      // "to": this.state.to,
      // "subject": this.state.subject,
      // "has_word": this.state.has_word,
      // "size": this.state.size,
      // "date": this.state.dateAdvance,
      // //  "search": "david@PrivateValueStore.com",
      // "search": 0,
      // "has_attachment": this.state.has_attachment,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/search_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(httpBody)
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.advancedsearchApiCallId;
  };

  setAdvanceSearch = (responseJson: any) => {
    this.setState({
      advancedsearchList: responseJson,
      onClickAdvanceSearch: false,
      advanceSearchPop: false,
    });
  };

  //////////////////////////////
  handleSearchTerm = (value: any) => {
    this.setState({ searchTerm: value });
    this.setState({ selectedMail: null });
    this.handleNormalSearch(value);
  };
  ////////////////////////////////
  debounce = (func: any, delay: any) => {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      func.bind(this)();
    }, delay);
  };

  handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    this.setState({ searchTerm: inputValue, selectedMail: null });

    // Call the debounced handler
    if (inputValue) {
      this.handleInputChangeDebounced(inputValue);
    } else {
      this.setState({ normalSearchArray: [] });
    }
  };
  handleInputChangeDebounced = (value: any) => {
    this.debounce(() => {
      this.handleNormalSearch(value);
    }, 500);
  };

  toggleSearchBox = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleSettingsPop = () => {
    this.setState({ settingsPopOpen: !this.state.settingsPopOpen });
    this.dataSyncGetAPI();
  };
  toggleCard = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleAdvanceSearchPop = () => {
    this.setState({
      isOpen: false,
      // advanceSearchPop: !this.state.advanceSearchPop,
      advanceSearchPop: true,
      onClickAdvanceSearch: true,
      ////////////////////////
    });
  };
  handleNormalSearch = (e: any) => {
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody = {
      user_name: this.state.username,
      user_password: this.state.password,
      maxResults: "",
      has_word: e,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.normalSearchKey = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/search_data`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.normalSearchKey;
  };
 
  formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    const now = new Date();

    if (date.toDateString() === now.toDateString()) {
      // Email received today, show time only
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (date.getFullYear() === now.getFullYear()) {
      // Email received in the current year, show date and month
      // return date.toLocaleDateString([], { day: 'numeric', month: 'short' });
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month}`;
    } else {
      // Email received in a previous year, show full date
      const day = date.toLocaleString("default", { day: "2-digit" });
      const month = date.toLocaleString("default", { month: "short" });
      return `${day} ${month} ${date.getFullYear()}`;
    }
  };

  clearFilterAdvanceSearch = () => {
    this.setState({
      from: "",
      to: "",
      subject: "",
      has_word: "",
      size: "",
      date: "",
      has_attachment: false,
      days: "",
      size_condition: "",
      wordtypedFrom : false,
      wordtypedTo : false,
    });
  };

  openOutLookReply = (sender_email: string) => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:${sender_email}`;
    emailLink.click();
  };
  openOutLookForward = (
    sender_email: string,
    subject: string,
    body: string
  ) => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:?subject=${encodeURIComponent(
      `Fwd: ${subject}`
    )}&body=${encodeURIComponent(body)}`;
    emailLink.click();
  };
  openOutlookCompose = () => {
    const emailLink = document.createElement("a");
    emailLink.href = `mailto:`;
    emailLink.click();
  };

  handleIndividualDownload = async (vault_id: string, save_set_id: string) => {
    try {
      const response = await fetch(
        `${baseURL.baseURL}/bx_block_api_search/api_searches/download?user_name=${this.state.username}&user_password=${this.state.password}&vault_id=${vault_id}&save_set_id=${save_set_id}`,
        { method: "GET" }
      );

      const blob = new Blob([response.url], { type: "message/rfc822" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = response.url;
      a.download = "example.eml";
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      console.log("Api Error" + JSON.stringify(error));
    }
  };

  handleMultipleFavEmails = (save_set_id: string, vault_id: string) => {
    this.setState({
      isClickedMultipleFav: true,
      clickedSaveSetId: save_set_id,
      clickedVaultId: vault_id,
    });
    const check = this.state.multipleFavArray.find(
      (e: any) => e.save_set_id == save_set_id
    );
    if (check) {
      const temp = this.state.multipleFavArray.filter(
        (e: any) => e.save_set_id !== save_set_id
      );
      this.setState({ multipleFavArray: [...temp] });
    } else {
      const temp = [...this.state.multipleFavArray, { save_set_id, vault_id }];
      this.setState({ multipleFavArray: [...temp] });
    }
  };
  MulptileFavAPIFunction = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      record_ids: this.state.multipleFavArray,
      favourite_checked: true,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.MultipleFavEmailsKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/toggle_favourites`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  CancelMultipleFav = () => {
    this.setState({ multipleFavArray: [], selectedMail: [] });
  };

  dataSyncGetAPI = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dataSyncGetAPIKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/get_sync_frequency?user_name=${this.state.username}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  dataSyncPostAPI = (e: any) => {
    this.setState({ dataSyncState: e.target.value });
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      user_name: "carly.simon",
      sync_frequency: `${this.state.dataSyncState}`, // 0: monthly, 1 weekly
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDataSyncKey = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_api_search/api_searches/set_sync_frequency`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // payload
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
