import React from "react";
import { Box, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
// import { darkStyles } from "../../framework/src/ThemeContext/ThemeProvider";
export const Attachments = ({ isDark }: any) => {
  const themeStyle = isDark ? darkStyles : lightStyles;
  return (
    <Box style={webStyle.attach_cont}>
      <AttachFile style={{...webStyle.attach_icon,...themeStyle.attach_icon}} />
      <Box style={webStyle.title_size_cont}>
        <Typography>Attachment 1</Typography>
        <Typography style={{ fontSize: "13px" }}>12.2 MB</Typography>
      </Box>
    </Box>
  );
};
const lightStyles = {
  attach_icon: {
    color: "#828dc5",
    backgroundColor: "#cacfeb",
  },
};
const darkStyles = {
  attach_icon: {
    color: "black",
    backgroundColor: "#9a999b",
  },
};
const webStyle = {
  attach_cont: {
    display: "flex",
    gap: "10px",
  },
  attach_icon: {
    fontSize: "13px",
    backgroundColor: "#BEC5E4",
    color: "#828dc5",
    borderRadius: "50%",
    padding: "4px",
    fontWeight: 600,
    marginTop: "3px",
  },
  title_size_cont: {
    fontSize: "18px",
  },
};
