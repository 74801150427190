import React, { Component } from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Checkbox,
  Divider,
  Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./FilterWeb.css";
import { TFilter } from "../../blocks/Cache2/src/Cache2Controller.web";
// import FilteroptionsController, {
//   Props,
//   configJSON
// } from "./FilteroptionsController";
interface Props {
  handleClose: () => void;
  handleClear: any;
  //  handleChecks : any,
  //  selected : any,
  isDark: any;
  handleApplyFilterBtn: () => void;
  //  filterOthers : any;
  selectedFilter: Map<string, TFilter>;
  otherDays: string;
  updateFilter: (data: TFilter) => void;
  // disableKey: Array<string>;
  checkDateBetween: (date: string, days: number) => boolean;
  tabState: any;
}
export default class FilterWeb extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const isDark = this.context.theme === "dark";
    const themeStyles = isDark ? darkStyles : lightStyles;
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
    });
    const attributeKeys = {
      sendDate: "sent_dt",
      download: "downloaded_date",
    };

    const tabkeys = {
      download: "Downloaded",
    };

    const filterOptions: Array<TFilter> = [
      {
        type: "number",
        title: "Last 7 days",
        value: 7,
        key:
          this.props.tabState == tabkeys.download
            ? attributeKeys.download
            : attributeKeys.sendDate,
        callback: (item) => this.props.checkDateBetween(item, 7),
      },
      {
        type: "number",
        title: "Last 30 days",
        value: 30,
        key:
          this.props.tabState == tabkeys.download
            ? attributeKeys.download
            : attributeKeys.sendDate,
        callback: (item) => this.props.checkDateBetween(item, 30),
      },
      {
        type: "number",
        title: "Last 60 days",
        value: 60,
        key:
          this.props.tabState == tabkeys.download
            ? attributeKeys.download
            : attributeKeys.sendDate,
        callback: (item) => this.props.checkDateBetween(item, 60),
      },
      {
        type: "boolean",
        title: "Has attachment",
        value: true,
        key: "has_attatchments",
        callback: (item) => item === true,
      },
    ];

    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theming}>
        <Container
          data-test-id="filterOptionsContainer"
          style={{
            width: "21%",
            position: "absolute",
            top: "14%",
            left: "30%",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "0px",
            zIndex: 9999,
            // ...themeStyles.containerBackground,
            backgroundColor: this.props.isDark ? "black " : "white",
          }}
          className="filterContainerMain"
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px",
              alignItems: "center",
            }}
          >
            <Typography style={webStyle.filter_title_typo}>
              Filter By
            </Typography>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={this.props.handleClear}
              data-test-id="clearFilter"
            >
              Clear
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gridGap: "8px",
            }}
          >
            {filterOptions.map((ele, i) => (
              <Box
                key={ele.title}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                }}
                // onClick={() =>
                //   this.props.disableKey.includes(ele.title)
                //     ? null
                //     : this.props.updateFilter(ele)
                // }
                onClick={() =>
                  this.props.updateFilter(ele)
                }
                data-test-id="options"
              >
                <Typography style={webStyle.filter_typo}>
                  {ele.title}
                </Typography>
                <Checkbox
                  style={{
                    position: "absolute",
                    right: 3,
                    ...themeStyles.checkbox_color,
                    color: this.props.isDark ? "grey" : "black",
                  }}
                  // disabled={this.props.disableKey.includes(ele.title)}
                  size="small"
                  value={ele.title}
                  onChange={() => this.props.updateFilter(ele)}
                  checked={Boolean(this.props.selectedFilter.get(ele.title))}
                />
              </Box>
            ))}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
              }}
            >
              <label style={{ ...webStyle.filter_typo, marginRight: "5px" }}>
                Other days :{" "}
              </label>
              <input
                type="text"
                // disabled={this.props.disableKey.includes("otherDays")}
                value={this.props.otherDays}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  this.props.updateFilter({
                    type: "onchange",
                    title: "otherDays",
                    value: value,
                    key:
                      this.props.tabState == tabkeys.download
                        ? attributeKeys.download
                        : attributeKeys.sendDate,
                    callback: (item) =>
                      this.props.checkDateBetween(item, Number(value)),
                  });
                }}
                style={{
                  backgroundColor: this.props.isDark ? "black" : "#fcfafa",
                  color: this.props.isDark ? "white" : "black",
                  width: "70px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "center",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gridGap: "10px",
                paddingLeft: "10px",
                paddingBottom: "20px",
              }}
            >
              <button
                style={{
                  marginRight: "0.5em",
                  marginLeft: "0.6em",
                  backgroundColor: "yellow",
                  border: "1px solid yellow",
                }}
                className="btn"
                onClick={this.props.handleApplyFilterBtn}
                data-test-id="applyButton"
              >
                Apply
              </button>
              <button
                className="btn"
                style={{
                  backgroundColor: this.props.isDark ? "grey" : "#fcfafa",
                  color: "#aba4a4",
                  border: "none",
                }}
                onClick={this.props.handleClose}
              >
                Cancel
              </button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

const lightStyles = {
  containerBackground: {
    backgroundColor: "white",
    color: "black",
  },
  checkbox_color: {
    color: "black",
  },
};
const darkStyles = {
  containerBackground: {
    backgroundColor: "black",
    border: "1px solid white",
    color: "white",
  },
  checkbox_color: {
    color: "yellow",
  },
};
const webStyle = {
  filter_title_typo: {
    fontSize: "15px",
  },
  filter_typo: {
    fontSize: "14px",
    cursor: "pointer",
  },
  apply_btn: {
    backgroundColor: "#F5F166",
    color: "#656565",
    borderRadius: "5px",
  },
  cancel_btn: {
    backgroundColor: "white",
    color: "#656565",
    borderRadius: "5px",
  },
};
// Customizable Area End
