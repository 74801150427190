import React, { useState, useEffect, ReactNode } from 'react'
import { Appearance, ColorSchemeName } from 'react-native-appearance';
import { SafeAreaView, View, StatusBar, StyleSheet } from 'react-native'
import { getStorageData } from '../Utilities';
export const { palette, darkStyles, lightStyles } = require('./ThemeStyles')

const defaultMode: ColorSchemeName = Appearance.getColorScheme()
export const THEME_STORED_KEY = 'THEME_STORED_KEY'
export const ThemeContext = React.createContext({
  theme: '',
  setAppMode: (mode: string) => { },
})

export const ManageThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeDeviceState, setThemeState] = useState<ColorSchemeName>(defaultMode)
  const [themeAppState, setThemeAppState] = useState('')
  const [theme, setTheme] = useState('')

  const setAppMode = (mode: string) => {
    setThemeAppState(mode)
  }

  useEffect(() => {
    getStorageData(THEME_STORED_KEY).then((res) => {
      setThemeAppState(res || '')
    })

    const subscription = Appearance.addChangeListener(({ colorScheme }) => {
      setThemeState(colorScheme)
    })

    return () => subscription.remove()
  }, [])

  useEffect(() => {

    setTheme(themeAppState || themeDeviceState)

  }, [themeAppState, themeDeviceState])
  return (
    <ThemeContext.Provider value={{
      setAppMode,
      theme: theme
    }}>
      <MyStatusBar backgroundColor={theme === 'dark' ?
        palette.dark.background : palette.light.background}
        barStyle={theme === 'dark' ? "light-content" : "dark-content"} />
      {children}
    </ThemeContext.Provider>
  )
}

const MyStatusBar = ({ backgroundColor, ...props }: any) => (
  <View style={[styles.statusBar, { backgroundColor }]}>
    <SafeAreaView>
      <StatusBar translucent backgroundColor={backgroundColor} {...props} />
    </SafeAreaView>
  </View>
);

const STATUSBAR_HEIGHT = StatusBar.currentHeight;
const APPBAR_HEIGHT = 44;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  statusBar: {
    height: STATUSBAR_HEIGHT,
  },
  appBar: {
    backgroundColor: '#79B45D',
    height: APPBAR_HEIGHT,
  },
  content: {
    flex: 1,
    backgroundColor: '#33373B',
  },
});