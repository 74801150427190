// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  withStyles,
  Switch,
  Select,
  MenuItem,
  WithStyles
} from "@material-ui/core";
import React from "react";
import { palette } from "../../../framework/src/ThemeContext/ThemeProvider";
import "./Settings5Web.css"


// Customizable Area End

 import Settings5WebController,{Props,configJSON}  from "./Settings5WebController.web";


export default class Settings5 extends Settings5WebController {
 
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyle : lightStyle;
    return (
      <Container
        style={{ ...styles.safaArea, ...themeStyle.background }}
        maxWidth={false}
        className="xyz"
        data-test-id="stopProBtn"
        onClick = {(e)=>  e.stopPropagation()}
      >
        <Box style={styles.flex}>
          <Typography style={themeStyle.title}>
            {configJSON.lightThemeTitle}
          </Typography>
          <div>
            <CustomSwitch data-test-id="toggle-button-theme" onChange={() => this.toggleTheme()} checked={isDark} />
          </div>
          <Typography style={themeStyle.title}>
            {configJSON.darkThemeTitle}
          </Typography>
        </Box>
        <Box style={styles.flex}>
          <Typography style={themeStyle.title}>Data Sync</Typography>
          <Box>
            
            <Select 
             value={this.props.dataSyncState}
             label="Monthly"
             placeholder='Monthly'
             onChange={this.props.dataSyncPostAPI}
            MenuProps={{
          
            PaperProps: {
              style: {
                padding: 0,
                background:"none",
                boxShadow: "none",
                zIndex : 1001,
                
              }
            }
          }}
            >
             <MenuItem value="0" 
              style={!isDark ? {backgroundColor:"white",color:"black"}:  {backgroundColor:"black",color:"white"}}
             >Monthly</MenuItem>
              <MenuItem value="1" 
              style={!isDark ? {backgroundColor:"white",color:"black"}:  {backgroundColor:"black",color:"white"}}
              >Weekly</MenuItem>
            </Select>
          </Box>
        </Box>
      </Container>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}
// Customizable Area Start
const darkStyle = {
  background: {
    backgroundColor: palette.dark.background,
  },
  title: {
    color: palette.dark.title,
  },
  subTitle: {
    color: palette.dark.subTitle,
  }
}

const lightStyle = {
  background: {
    backgroundColor: palette.light.background,
  },
  title: {
    color: palette.light.title,
  },
  subTitle: {
    color: palette.light.subTitle,
  }
}
const styles = {
  safaArea: {
    position: "absolute" as "absolute",
    top: "7%",
    right: "7%",
    width: "21%",
    backgroundColor: "#f6f7f9",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    zIndex: 9999,
  },

  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
};
const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#343434",
      "& + $track": {
        backgroundColor: "#F5F202",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#F5F202",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#d1d1d1",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

interface CustomSelectProps extends WithStyles<typeof styles> {
  // Define any other props you expect here
  // For example, you can include a callback function or other custom props.
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  value?: number;
}

// Customizable Area End
