import React from "react";
import Cache2Controller from "./Cache2Controller.web";
import {
  Container,
  Box,
  Input,
  Paper,
  Typography,
  FormLabel,
  Divider,
  NativeSelect,
  Checkbox,
  Button,
  TextField,
} from "@material-ui/core";
import {
  Settings,
  Search,
  Clear,
  CalendarTodayRounded,
} from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Settings5 from "../../Settings5/src/Settings5.web";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Cache2Web.css";
import Backdrop from "@material-ui/core/Backdrop"

export default class AppHeader extends Cache2Controller {
  sendDataToParent = () => {
    const data = {
      // "user_name": "carly.simon",
      // "user_password": "P@55word",
      user_name: this.state.username,
      user_password: this.state.password,
      startIndex: 0,
      maxResults: 10,
      has_word: this.state.has_word,
      subject: this.state.subject,
      from: this.state.from,
      to: this.state.to,
      has_attachment: this.state.has_attachment,
      size: this.state.size,
      date: this.state.date,
      search: "0",
      size_unit: this.state.size_unit,
      size_condition: this.state.size_condition,
      days: this.state.days,
    };

    this.setState({ onClickAdvanceSearch: false });
    // Call the callback function passed from the parent with the data as an argument
    this.props.onDataReceived(data);
    // this.props.normalDetailArray(data)
  };
  sendDatatoChildForNormalSearch = (data: any) => {
    this.props.normalDetailArray(data);
    this.setState({ isOpen: false });
  };

  render() {
    const maxSelectableDate = new Date();
    const mode = this.context.theme;
    const isDark = this.props.isDark;
    const themeStyles = isDark ? darkStyles : lightStyles;
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
    });

    return (
      <ThemeProvider theme={theming}>
        <Paper>
          <Container
            maxWidth="xl"
            style={{ ...styles.contianer, ...themeStyles.border }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gridGap: "20px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    display: "flex",
                    padding: "0px 10px",
                    height: "35px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    data-test-id="searchInputText"
                    disableUnderline={true}
                    placeholder="Search"
                    value={this.state.searchTerm}
                    style={{ width: "500px" }}
                     onChange={this.handleInputChange}
                    onClick={() => this.toggleSearchBox()}
                  />
                  <Search />
                </Box>
              
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gridGap: "20px",
                  alignItems: "center",
                }}
              >
                <Settings
                  data-test-id="settingButton"
                  onClick={this.handleSettingsPop}
                  style={{ cursor: "pointer" }}
                />
                {this.state.settingsPopOpen && (
                  <>
                    <Backdrop style={{background : "transparent", zIndex :1300}} open={this.state.settingsPopOpen}  onClick={() => this.setState({ settingsPopOpen: false })} data-test-id="settingModal">
                    <Settings5
                        navigation={this.props.navigation}
                        dataSyncPostAPI={(e: any) => this.dataSyncPostAPI(e)}
                        dataSyncState={this.state.dataSyncState}
                        id=""
                        drawer={{
                          navigation: {
                            toggleDrawer: () => ({}),
                          },
                          route: {
                            name: "",
                          },
                        }}
                        data-test-id="settingsTest"
                      />
                    </Backdrop>
                  </>
                )}
                 
                <Box
                  sx={{
                    height: "35px",
                    width: "35px",
                  }}
                >
                  <img
                    src="https://banner2.kisspng.com/20180615/rtc/kisspng-avatar-user-profile-male-logo-profile-icon-5b238cb002ed52.870627731529056432012.jpg"
                    alt="profile_img"
                    width="100%"
                    style={{ borderRadius: "50%" }}
                  />
                </Box>
              </Box>
            </Box>
            
            {this.state.isOpen && (
              <>
              <Backdrop style={{background : "transparent",  zIndex :1300
               }} open={this.state.isOpen}  onClick={() => this.setState({ 
                isOpen: false ,onClickAdvanceSearch : false,advanceSearchPop:false})} data-test-id="backdropBtn">

              <Box
                //onMouseLeave={this.toggleCard}
                sx={{
                  position: "absolute",
                  top: "45px",
                  zIndex: 1,
                  left : "210px"
                }}
                // style={{...themeStyles.container}}
                data-test-id="backdropStopPropagation"
                 onClick = {(e)=>  e.stopPropagation()}
              >
                <Paper
                  elevation={3}
                  variant="outlined"
                  square
                  style={{
                    //boxShadow: "4px 4px 20px 2px #ebebeb",
                    width: "542px",
                    // height: "220px",
                    height: "330px",
                    //  backgroundColor: "white",
                    marginTop: "2px",
                    overflowY: "auto",
                    // overflowY: "hidden",
                    overflowX: "hidden",
                    ...themeStyles.container,
                    // ...themeStyles.ccard,
                  }}
                >
                   
                  <Box sx={searchCard.mainCard}>
                    <Box sx={searchCard.box1}>
                      <Typography>Best Matches</Typography>
                      <Typography
                        onClick={this.handleAdvanceSearchPop}
                        data-test-id="advanceSearchLink"
                        style={{
                          cursor: "pointer",
                          color: isDark ? "yellow" : "black",
                        }}
                      >
                        Advanced Search
                      </Typography>
                    </Box>
                    {this.state.normalSearchArray.length > 0
                      ? this.state.normalSearchArray.map((ele: any,index:any) => (
                          <Box
                            key = {index}
                            data-test-id="normalSearch"
                            style={searchCard.box2}
                            onClick={() =>
                              this.sendDatatoChildForNormalSearch(ele)
                            }
                          >
                            <Box
                              style={{
                                marginRight: "7px",
                                border: "1px solid #68686b",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "2em",
                                height: "2em",

                                boxSizing: "border-box",
                                color: "white",
                                backgroundColor: "rgb(166, 179, 238)",
                                paddingTop: "4px",
                              }}
                            >
                              {ele.attributes.author[0].toUpperCase()}
                            </Box>
                            <Box sx={searchCard.box3}>
                              <Typography
                                style={{ fontSize: "1em", fontWeight: 400 }}
                              >
                                {ele.attributes.author}
                              </Typography>
                              <Typography style={{ fontSize: "0.6em" }}>
                                {ele.attributes.sender_email}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      : <Typography style={{textAlign:"center"}}>No Data</Typography>}
                    <Box>
                      {this.state.normalSearchArray.length > 0 ? (
                        <Box style={{ marginBottom: "7px" }}>
                          Suggested Search
                        </Box>
                      ) : null}

                      {this.state.normalSearchArray.length > 0
                        ? this.state.normalSearchArray.map((ele: any) => (
                            <Box
                              style={searchCard.box2}
                              onClick={() =>
                                this.sendDatatoChildForNormalSearch(ele)
                              }
                              data-test-id="normalSearch"
                            >
                              <Box
                                style={{
                                  border: "none",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",

                                  backgroundColor: "#DCDCDC",
                                  width: "2em",
                                  height: "2em",
                                  boxSizing: "border-box",
                                  paddingTop: "4px",
                                  color: "white",
                                  marginRight: "7px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="black"
                                  className="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                  />
                                </svg>
                              </Box>
                              <Box sx={searchCard.box3}>
                                <Typography
                                  style={{ fontSize: "1em", fontWeight: 400 }}
                                >
                                  {ele.attributes.author}
                                </Typography>
                                {/* <Typography style={{ fontSize: "0.6em", }}>{ele.attributes.sender_email}</Typography> */}
                              </Box>
                            </Box>
                          ))
                        : null}
                    </Box>
                  </Box>
                 
                </Paper>
              </Box>
            </Backdrop></>
            )}
                
            {this.state.onClickAdvanceSearch && this.state.advanceSearchPop ? (
              <>
              <Backdrop style={{background : "transparent",  zIndex :1300
               }} open={this.state.advanceSearchPop}  onClick={() => this.setState({ 
              onClickAdvanceSearch : false,advanceSearchPop:false})} data-test-id="backdropAdvance">

              <Box
                style={{
                  position: "absolute",
                  top: "12%",
                  left: "40%",
                  width: "24%",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  ...themeStyles.container,
                }}
                data-test-id="backdropStopPropagationAdvance"
                onClick = {(e)=>  e.stopPropagation()}
              >
                <Box style={webStyles.title_box}>
                  <Typography style={{ fontWeight: 600 }}>
                    Advance Search
                  </Typography>
                  <Clear
                    {...this.props}
                    onClick={() =>
                      this.setState({ onClickAdvanceSearch: false })
                    }
                    style={{ cursor: "pointer" }}
                    data-test-id="btnCloseModal"
                  />
                </Box>
                <Divider style={{ width: "93%", margin: "auto" }} />
                <Box
                  style={{
                    width: "90%",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px",
                  }}
                >
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      htmlFor=""
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                        marginRight: "0.2em",
                      }}
                    >
                      From :
                    </FormLabel>
                    <input
                      type="text"
                      name="from"
                      style={
                        this.state.wordtypedFrom 
                          ? {
                              ...webStyles.input_style,
                              ...themeStyles.form_label,
                              backgroundColor: (isDark && this.state.wordtypedFrom)? "black" : "#dae0eb",
                              // backgroundColor: "#dae0eb",
                              borderRadius: "7em",
                              padding: "0.2em",
                              border: "1px solid #74767a",
                              borderBottom: "1px solid #74767a",
                            }
                          : {
                              ...webStyles.input_style,
                              ...themeStyles.form_label,
                               backgroundColor: "initial",
                              borderRadius: "initial",
                              padding: "initial",
                              border: "initial",
                              borderBottom: "1px solid grey",
                            }
                      }
                      // InputProps={{
                      //   disableUnderline: true,
                      // }}
                      value={this.state.from}
                      data-test-id="inputFromText"
                      onChange={this.handleInput}
                    />
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      htmlFor=""
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                        marginRight: "0.2em",
                      }}
                    >
                      To :
                    </FormLabel>
                    <input
                      type="text"
                      name="to"
                      style={
                        this.state.wordtypedTo
                          ? {
                              ...webStyles.input_style,
                              ...themeStyles.form_label,
                              // backgroundColor: "#dae0eb",
                              backgroundColor: (isDark && this.state.wordtypedTo)? "black" : "#dae0eb",
                              borderRadius: "7em",
                              padding: "0.2em",
                              border: "1px solid #74767a",
                              borderBottom: "1px solid #74767a",
                            }
                          : {
                              ...webStyles.input_style,
                              ...themeStyles.form_label,

                              backgroundColor: "initial",
                              borderRadius: "initial",
                              padding: "initial",
                              border: "initial",
                              borderBottom: "1px solid grey",
                            }
                      }
                      // InputProps={{
                      //   disableUnderline: false,
                      // }}
                      value={this.state.to}
                      data-test-id="inputToText"
                      onChange={this.handleInput}
                    />
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      htmlFor=""
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Subject :
                    </FormLabel>
                    <input
                      name="subject"
                      type="text"
                      style={{
                        ...webStyles.input_style,
                        ...themeStyles.form_label,
                      }}
                      // InputProps={{
                      //   disableUnderline: true,
                      // }}
                      value={this.state.subject}
                      data-test-id="inputSubjectText"
                      onChange={this.handleInput}
                    />
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      htmlFor=""
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Has the words :
                    </FormLabel>
                    <input
                      type="text"
                      name="has_word"
                      style={{
                        ...webStyles.input_style,
                        ...themeStyles.form_label,
                      }}
                      // InputProps={{
                      //   disableUnderline: true,
                      // }}
                      data-test-id="inputHadWordsText"
                      onChange={this.handleInput}
                      value={this.state.has_word}
                    />
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      htmlFor=""
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Size :
                    </FormLabel>

                    <NativeSelect
                      style={{
                        fontSize: "13px",
                        width: "35%",
                        borderBottom: "1px solid grey",
                        // color: "grey",
                      }}
                      defaultValue="gt"
                      inputProps={{
                        id: "uncontrolled-native",
                      }}
                      disableUnderline
                      onChange={this.handleInput}
                      name="size_condition"
                      value={this.state.size_condition}
                    >
                      <option value="gt">greater than</option>
                      <option value="lt">less than</option>
                    </NativeSelect>
                    <TextField
                      type="tel"
                      style={{
                        fontSize: "12px",
                        borderBottom: "1px solid grey",
                        marginTop: "-4px",
                        backgroundColor: "transparent",
                        width: "25%",
                        textAlign: "center",
                      }}
                      onChange={this.handleInput}
                      name="size"
                      value={this.state.size}
                      // InputProps={{
                      //   disableUnderline: false,
                      // }}
                    />
                    <NativeSelect
                      style={{
                        fontSize: "13px",

                        borderBottom: "1px solid grey",
                        width: "25%",
                        // color: "grey",
                      }}
                      defaultValue="mb"
                      name="size_unit"
                      value={this.state.size_unit}
                      inputProps={{
                        id: "uncontrolled-native",
                      }}
                      disableUnderline
                      onChange={this.handleInput}
                    >
                      <option value="mb">MB</option>
                      <option value="gb">GB</option>
                      <option value="kb">KB</option>
                    </NativeSelect>
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Date with in :
                    </FormLabel>

                    <NativeSelect
                      style={{
                        fontSize: "13px",
                        width: "35%",
                        borderBottom: "1px solid grey",
                        // color: "grey",
                      }}
                      defaultValue="1day"
                      inputProps={{
                        id: "uncontrolled-native",
                      }}
                      variant="standard"
                      disableUnderline
                      name="days"
                      value={this.state.days}
                      onChange={this.handleInput}
                    >
                      <option value="1day">1 day</option>
                      <option value="7days">7 days</option>
                      <option value="30days">30 days</option>
                      <option value="10days">10 days</option>
                    </NativeSelect>
                    <Box
                      style={{
                        ...webStyles.input_date,
                        ...themeStyles.form_label,
                      }}
                    >
                      <Typography style={{ fontSize: "13px" }}>
                        {this.state.date}
                      </Typography>
                      <CalendarTodayRounded
                        onClick={this.handleDatePicker}
                        style={{
                          zIndex: 9999999,
                          color: isDark ? "white" : "black",
                          fontSize: "15px",
                          cursor: "pointer",
                          marginBottom: "5px",
                        }}
                        data-test-id="calendarOpen"
                        //onChange={this.handleInput}
                        // name="date"
                      />
                      {this.state.datePickerOpen ? (
                        <Box
                          style={{
                            position: "absolute",
                            zIndex: 99999,
                            backgroundColor: "black",
                            top: "62%",
                            left: "80%",
                          }}
                          data-test-id="calenderBox"
                        >
                          <Calendar
                            // name="date"
                            onChange={this.handleDateChange}
                            // value={this.state.selectedDate}
                            value={this.state.date}
                            maxDate={maxSelectableDate}
                            // style={webStyles.calendar}
                            data-test-id="dateChange"
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box style={webStyles.form_control_wrapper}>
                    <FormLabel
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Search :
                    </FormLabel>

                    <NativeSelect
                      style={{
                        fontSize: "13px",
                        flexGrow: 1,
                        borderBottom: "1px solid grey",
                        // color: "grey",
                      }}
                      defaultValue="inbox"
                      inputProps={{
                        id: "uncontrolled-native",
                      }}
                      disableUnderline
                      onChange={this.handleInput}
                      name="search"
                    >
                      {/* <option value="inbox">All Inboxes</option>
                    <option value="drafts">drafts</option> */}
                      <option value="David@vault.com">David@vault.com</option>
                    </NativeSelect>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      onChange={this.handleInput}
                      name="has_attachment"
                      checked={this.state.has_attachment}
                    />
                    <FormLabel
                      style={{
                        ...webStyles.form_label,
                        ...themeStyles.form_label,
                      }}
                    >
                      Has attachment
                    </FormLabel>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      margin: "auto",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button
                      style={
                        isDark
                          ? webStyles.create_btn_dark
                          : webStyles.create_btn
                      }
                      onClick={this.clearFilterAdvanceSearch}
                    >
                      Clear Filter
                    </Button> */}
                    <button
                      style={
                        isDark
                          ? webStyles.create_btn_dark
                          : webStyles.create_btn
                      }
                      onClick={this.clearFilterAdvanceSearch}
                    >
                      Clear filter
                    </button>
                    <button
                      style={webStyles.search_btn}
                      data-test-id="btnGetAdvancedSearchList"
                      onClick={this.sendDataToParent}
                    >
                      Search
                    </button>
                    {/* <Button
                      style={webStyles.search_btn}
                      data-test-id="btnGetAdvancedSearchList"
                      onClick={this.sendDataToParent}
                    >
                      Search
                    </Button> */}
                  </Box>
                </Box>
              </Box>
              </Backdrop> </>
            ) : null}
            
          </Container>
        </Paper>
      </ThemeProvider>
    );
  }
}

const lightStyles = {
  border: { borderBottom: "1px solid #f3f3fd" },
  container: {
    // backgroundColor: "#f6f7f9",
    backgroundColor: "white",
    //boxShadow: "4px 4px 20px 2px #ebebeb",
  },
  form_label: {
    color: "black",
  },
};
const darkStyles = {
  border: {
    borderBottom: "1px solid #2c2c2c",
    //borderBottom: "1px solid white"
  },

  container: {
    // backgroundColor: "#292c31",
    backgroundColor: "#262626",
    border: "none",
  },
  form_label: {
    color: "white",
  },
  
  ccard: {
    backgroundColor: "black",
    border: "2px solid black",
  },
};
const styles = {
  contianer: {
    padding: "10px 15px",
    // width: "90%",
  },
 
};
const searchCard = {
  mainCard: {
    boxSizing: "border-box",
    width: "542px",
    padding: "20px",
    // height: "200px",
    height: "330px",
  },
  box1: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "7px",
    marginRight: "5px",
  },
  box2: {
    display: "flex",
    // marginBottom: "7px",
    marginBottom: "15px",
    alignItems: "center",
    cursor: "pointer",
  },
  box3: {
    display: "flex",
    flexDirection: "column",
  },
};
const modalStyle = {
 backgroundColor: "white",
};
const webStyles = {
  container: {
    position: "absolute",
    left: "40%",
    width: "24%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    top: "12%",
  },
  container_dark: {
    width: "24%",
    left: "40%",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    top: "12%",
    backgroundColor: "#292c31",
    position: "absolute",
  },
  title_box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
  },
  form_wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    gap: "17px",
    width: "90%",
  },
  form_control_wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  form_label: {
    color: "black",
    fontSize: "13px",
  },
  // form_label_dark: { color: "white", fontSize: "13px" },
  input_style: {
    flexGrow: 1,
    border: "none",
    backgroundColor: "transparent",
    borderBottom: "1px solid grey",
    padding: "0px 5px",
    outline: "none",
  },
  input_date: {
    marginTop: "8px",
    width: "35%",
    fontSize: "10px",
    borderBottom: "1px solid grey",
    // color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  create_btn: {
    borderRadius: "45px",
    // backgroundColor: "#ffffff",
    backgroundColor: "white",
    fontSize: "14px",
    padding: "8px",
    // textTransform: "none",
    fontWeight: 600,
    width: "45%",
    border: "none",
  },
  create_btn_dark: {
    backgroundColor: "#363636",
    padding: "8px",
    borderRadius: "45px",
    fontSize: "14px",
    width: "45%",
    border: "none",
    // textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#424040",
    },
  },
  search_btn: {
    width: "45%",
    color: "#5b5c5e",
    padding: "8px",
    backgroundColor: "#F5F202",
    fontSize: "14px",
    borderRadius: "45px",
    border: "none",
    // textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#f0ed47",
      color: "#5b5c5e",
    },
  },
  customButton: {
    fontSize: "12px",
    padding: "0px",
  },
  calendarPopover: {
    "& .MuiPaper-root-641": {
      backgroundColor: "red !important",
      /* Add more styles as needed */
    },
  },
  calendar: {
    backgroundColor: "white",
  },
};
const advanceBox = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
};




